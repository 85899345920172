import React, {useEffect, useState} from "react";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";

function App() {
    const [resumeData, setResumeData] = useState({});

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () => {
        try {
            const response = await fetch("./isaacResume.json");
            const data = await response.json();
            setResumeData(data);
            console.log("data", data); // Log the received data
            console.log("resume", resumeData.main); // Log the received data
        } catch (error) {
            console.log(error);
            alert(error);
        }
    };

    return (
        <div className="App">
            <Header data={
                resumeData.main
            }/>
            <About data={
                resumeData.main
            }/>
            <Resume data={resumeData.resume}/> 
            {/* <Portfolio data={resumeData.portfolio} /> */}
            <Footer data={
                resumeData.main
            }/>
        </div>
    );
}

export default App;
